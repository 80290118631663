<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <b-card-text>{{ $t("Images") }} {{ $t("All") }}
                  {{ images.length }} {{ $t("Images") }}</b-card-text>
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-row>
                      <b-col
                        v-for="(item, index) in images"
                        :key="index"
                        :cols="images.length < 4 ? '12' : '3'"
                      >
                        <b-img
                          fluid-grow
                          :src="item"
                          rounded
                          class="mr-1 mb-1 imgs-show"
                          height="120"
                        />
                        <b-button
                          variant="danger"
                          class="btn-remove"
                          @click="removeImages(index)"
                        >
                          {{ $t("Delete") }} ({{ index + 1 }})
                        </b-button>
                      </b-col>
                    </b-row>

                    <!--/ avatar -->
                  </b-media-aside>
                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputImages.$el.click()"
                    >
                      {{ $t("Upload") }}
                    </b-button>
                    <b-form-file
                      ref="refInputImages"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImagesRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImages"
                    >
                      {{ $t("Reset") }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t("Allowed") }} JPG, GIF {{ $t("or") }} PNG.
                      {{ $t("Max size of") }} 300KB</b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-card-text>{{ $t("Name") }}</b-card-text>
            </b-col>
            <b-col
              v-for="(item, index) in language"
              :key="index + 'name'"
              cols="12"
            >
              <!-- firstName -->
              <!-- firstName -->
              <b-form-group
                :label="item.name"
                :label-for="item.name + 'name'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name + 'name'"
                  :vid="item.name + 'name'"
                >
                  <b-form-input
                    v-if="name[index]"
                    :id="item.name + 'name'"
                    :name="item.name + 'name'"
                    :value="name[index]['value']"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="item.name"
                    @input="inputLanguage($event, item.lang, index, 'name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-card-text>{{ $t("Detail") }}</b-card-text>
            </b-col>
            <b-col
              v-for="(item, index) in language"
              :key="index + 'detail'"
              cols="12"
            >
              <!-- firstName -->
              <!-- firstName -->
              <b-form-group
                :label="item.name"
                :label-for="item.name + 'detail'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name + 'detail'"
                  :vid="item.name + 'detail'"
                >
                  <b-form-textarea
                    v-if="detail[index]"
                    :id="item.name + 'detail'"
                    :name="item.name + 'detail'"
                    :value="detail[index]['value']"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="item.name"
                    @input="inputLanguage($event, item.lang, index, 'detail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Price')"
                label-for="register-price"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Price"
                  vid="price"
                  rules=""
                >
                  <b-form-input
                    id="register-price"
                    v-model="price"
                    name="register-price"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Price')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Point')"
                label-for="register-point"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Point"
                  vid="point"
                  rules=""
                >
                  <b-form-input
                    id="register-point"
                    v-model="point"
                    name="register-point"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Point')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="$t('Type Queue')"
                label-for="register-option"
              >
                <validation-provider
                  #default="{ errors }"
                  name="option"
                  vid="option"
                  rules="required"
                >
                  <v-select
                    id="register-option"
                    v-model="typeQueue"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="Option"
                    :reduce="(Option) => Option.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Province')"
                label-for="register-province"
              >

                <v-select
                  id="register-province"
                  v-model="provinceId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="code"
                  :options="respDataProvince"
                  :reduce="(respDataProvince) => respDataProvince"
                  class="select-size-lg"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >

                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('District')"
                label-for="register-district"
              >

                <v-select
                  id="register-district"
                  v-model="districtId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="code"
                  :options="respDataDistrict"
                  :reduce="(respDataDistrict) => respDataDistrict"
                  class="select-size-lg"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >

                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                </v-select>

              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Subdistrict')"
                label-for="register-sub-district"
              >

                <v-select
                  id="register-sub-district"
                  v-model="subDistrictId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="code"
                  :options="respDataSubDistrict"
                  :reduce="(respDataSubDistrict) => respDataSubDistrict"
                  class="select-size-lg"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >

                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ showFromCurrentLanguage(option.name) }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <hr>
            </b-col>
            <b-col cols="12">
              <b-card-text>{{ $t("Search option") }}</b-card-text>
            </b-col>
            <b-col
              v-for="(item,index) in maxSearchOption"
              :key="`SearchOption_${index}`"
              cols="6"
            >
              <b-form-group
                :label="searchOptionLabel[index] ? searchOptionLabel[index].name: `${$t('Category')} ${index+1}`"
                label-for="register-search-option"
              >
                <validation-provider
                  #default="{ errors }"
                  name="search-option"
                  vid="search-option"
                  rules=""
                >
                  <v-select
                    id="register-search-option"
                    v-model="selectedOption[index]"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="filterPosition(index)"
                    :reduce="(searchOption) => searchOption._id"
                    class="select-size-lg"
                    @input="onSelectOption(index,$event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <hr>
            </b-col>
            <b-col cols="12">
              <b-card-text>{{ $t("List search option") }}</b-card-text>
            </b-col>
            <b-col
              v-for="(item,index) in searchOptionList"
              :key="index"
              cols="6"
            >
              <b-form-group
                :label="searchOptionLabel[index] ? searchOptionLabel[index].name: `${$t('Category')} ${index+1}`"
                label-for="register-package"
              >
                <validation-provider
                  #default="{ errors }"
                  name="package"
                  vid="package"
                  rules=""
                >
                  <v-select
                    id="register-package"
                    v-model="selectedListOption[item._id]"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="item.list"
                    :reduce="(dataListSearchOption) => dataListSearchOption._id"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <hr>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t("Submit") }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  // BMediaAside,
  BMediaBody,
  // BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BLink,
  BMediaAside,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import storeModuleSearchOption from '@/store/services/hero/searchOption'

import vSelect from 'vue-select'
import store from '@/store'

const STORE_MODULE_NAME_SEARCH_OPTION = 'searchOption'

// eslint-disable-next-line import/extensions
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    // BMediaAside,
    BMediaBody,
    // BLink,
    BImg,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BMediaAside,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleProvince: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      images: [],
      name: [],
      detail: [],
      typeQueue: '',
      price: 0,
      point: 0,
      provinceId: null,
      districtId: null,
      subDistrictId: null,
      selectedOption: {},
      selectedListOption: {},
      required,
    }
  },
  computed: {
    Option() {
      return [
        { name: this.$t('Period'), value: 'period' },
        { name: this.$t('Order'), value: 'order' },
      ]
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
    },
    respDataProvince() {
      return store.state[this.storeModuleProvince].respDataProvince != null ? store.state[this.storeModuleProvince].respDataProvince.data : []
    },
    respDataDistrict() {
      return store.state[this.storeModuleProvince].respDataDistrict != null ? store.state[this.storeModuleProvince].respDataDistrict.data : []
    },
    respDataSubDistrict() {
      return store.state[this.storeModuleProvince].respDataSubDistrict != null ? store.state[this.storeModuleProvince].respDataSubDistrict.data : []
    },
    options() {
      const dataOption = Object.keys(this.selectedOption)
      return { data: dataOption, length: dataOption.length }
    },
    searchOption() {
      return store.state[STORE_MODULE_NAME_SEARCH_OPTION].respData != null ? store.state[STORE_MODULE_NAME_SEARCH_OPTION].respData.data.map(e => {
        let name = ''
        const indexLang = e.name.findIndex(i => i.lang.toString() === this.$i18n.locale.toString())
        if (indexLang > -1) {
          name = e.name[indexLang].value
        }
        return {
          ...e,
          name,
        }
      }) : []
    },
    searchOptionLabel() {
      return store.state[STORE_MODULE_NAME_SEARCH_OPTION].respData != null ? store.state[STORE_MODULE_NAME_SEARCH_OPTION].respData.label : []
    },
    maxSearchOption() {
      if (this.searchOption.length === 0) {
        return 0
      }
      return this.searchOption.reduce((max, obj) => (obj.position > max.position ? obj : max), this.searchOption[0]).position
    },
    searchOptionList() {
      return store.state[STORE_MODULE_NAME_SEARCH_OPTION].respDataListOption != null ? store.state[STORE_MODULE_NAME_SEARCH_OPTION].respDataListOption.data.map(e => {
        const list = e.list.map(l => {
          let name = ''
          const indexLang = l.name.findIndex(i => i.lang.toString() === this.$i18n.locale.toString())
          if (indexLang > -1) {
            name = l.name[indexLang].value
          }
          return {
            ...l,
            name,
          }
        })
        return {
          ...e,
          list,
        }
      }) : []
    },
  },
  watch: {
    provinceId(val) {
      this.districtId = null
      this.subDistrictId = null
      this.getDistrict(val)
    },
    districtId(val) {
      this.subDistrictId = null
      this.getSubDistrict(val)
    },
    isSidebarActive(val) {
      if (val) {
        this.initValues()

        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id,
            images,
            name,
            detail,
            typeQueue,
            price,
            point,
            provinceId,
            districtId,
            subDistrictId,
            selectedOption,
            selectedListOption,
          } = this.data
          this.dataId = _id
          this.images = images
          this.name = name
          this.detail = detail
          this.typeQueue = typeQueue
          this.price = price
          this.point = point
          this.provinceId = provinceId
          this.districtId = districtId
          this.subDistrictId = subDistrictId
          if (selectedOption.length !== 0) {
            const obj = selectedOption.reduce((acc, value, index) => {
              acc[index] = value
              return acc
            }, {})
            this.selectedOption = obj
          }
          if (selectedListOption.length !== 0) {
            const obj = selectedListOption.reduce((acc, value, index) => {
              acc[value.listSearchOptionId] = value._id
              return acc
            }, {})
            this.selectedListOption = obj
          }
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.name[index].lang)
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.detail.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.detail[index].lang)
              if (indexLanguage < 0) {
                this.detail.splice(index, 1)
              }
            }
          }
        }
      }
    },
  },
  created() {
    if (!store.hasModule(STORE_MODULE_NAME_SEARCH_OPTION)) store.registerModule(STORE_MODULE_NAME_SEARCH_OPTION, storeModuleSearchOption)
    this.getSearchOption()

    store
      .dispatch(`${this.storeModuleProvince}/getProvince`)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.showLoading = false
      }).catch(error => {
        this.showLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME_SEARCH_OPTION)) store.unregisterModule(STORE_MODULE_NAME_SEARCH_OPTION)
  },
  setup() {
    return {
      STORE_MODULE_NAME_SEARCH_OPTION,
    }
  },
  methods: {
    onSelectOption(position, id) {
      const obj = {
        [`${position}`]: id,
      }

      const keys = this.options.data

      keys.forEach(key => {
        // eslint-disable-next-line radix
        if (parseInt(key) > position) {
          delete this.selectedOption[key]
        }
      })

      this.selectedOption = {
        ...this.selectedOption,
        ...obj,
      }
    },
    filterPosition(position) {
      if (this.searchOption.hasOwnProperty(position)) {
        let data = this.searchOption
        if (position > 0) {
          data = data.filter(e => e.previousId !== null)
          if (this.selectedOption[position - 1]) {
            data = data.filter(e => e.previousId.toString() === this.selectedOption[position - 1].toString())
          }
        }
        data = data.filter(e => e.position.toString() === position.toString())
        return data
      }
      return []
    },
    getSearchOption() {
      this.showLoading = true

      store
        .dispatch(`${STORE_MODULE_NAME_SEARCH_OPTION}/get`, {})
        .then(result => {
          console.log('fetch Success : ', result)
          this.showLoading = false
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })

      this.showLoading = true
      store
        .dispatch(`${STORE_MODULE_NAME_SEARCH_OPTION}/optionGet`, {})
        .then(result => {
          console.log('fetch Success : ', result)
          this.showLoading = false
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    getDistrict(val) {
      if (val == null) {
        return
      }
      this.showLoading = true
      store
        .dispatch(`${this.storeModuleProvince}/getDistrict`, val.code)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.showLoading = false
        }).catch(error => {
          this.showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    getSubDistrict(val) {
      if (val == null || val === undefined) {
        return
      }
      this.showLoading = true
      store
        .dispatch(`${this.storeModuleProvince}/getSubDistrict`, val.code)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.showLoading = false
        }).catch(error => {
          this.showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    inputLanguage(input, lang, index, type) {
      console.log(input, lang, index)
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
        console.log(this.name)
      } else if (type === 'detail') {
        if (this.detail[index].lang === lang) {
          this.detail[index].value = input
        }
        console.log(this.detail)
      }
    },
    removeImages(index) {
      this.images.splice(index, 1)
    },
    inputImagesRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.images.push(e.target.result)
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
          this.$refs.refInputImages.reset()
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImages() {
      this.images = []
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.name = []
      this.detail = []
      this.typeQueue = ''
      this.price = 0
      this.point = 0
      this.provinceId = null
      this.districtId = null
      this.subDistrictId = null
      this.selectedOption = {}
      this.selectedListOption = {}

      // this.$refs.refInputEl.reset()
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            images: this.images,
            name: this.name,
            detail: this.detail,
            typeQueue: this.typeQueue,
            price: this.price,
            point: this.point,
            provinceId: this.provinceId,
            districtId: this.districtId,
            subDistrictId: this.subDistrictId,
            selectedOption: this.selectedOption,
            selectedListOption: this.selectedListOption,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.btn-remove {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}

.imgs-show {
  // width: 100%;
  height: 100px;
}
</style>
